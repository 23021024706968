.top-navigation {
    position: relative;
}

.top-navigation li {
    background: white;
}

.top-navigation li ul {
    background: #f7f2ed !important;
    border-bottom: 1px solid #dac0a7
}

.top-navigation li ul li {
    background: #f7f2ed !important;
    display: block;
}

header.header .header-main nav .top-navigation li ul li a {
    margin-right: 13px;
}

.top-navigation li.has-children ul {
    z-index: 100;
    margin-left: 0;
    display: none;
    position: absolute;
    width: auto;
    top: 47px;
}

.top-navigation li.has-children ul li {
    display: block !important;
    line-height: 0.3em;
    float: none !important;
}

.top-navigation li:hover.has-children ul {
    display: block !important;
}

.top-navigation li:hover.has-children ul li a {
    font-size: 1.2em;
}

.burger {
    display: none;
    float: right;
    margin-top: 8px;
    margin-left: 10px;
}


@media screen and (max-width: 932px) and (min-width: 0) {

    .top-navigation li.has-children{
        position: relative;
    }

    .top-navigation.is-active{
        display: block;
    }

    .top-navigation li:hover.has-children ul {
        display: none !important;
    }

    .top-navigation li.has-children ul.is-active {
        display: block!important;
    }

    .burger {
        display: block;
    }
    .top-navigation {
        display: none;
        margin-left: 0;
        width: 100%;
    }

    header.header .header-main nav {
        float: none;
        position: absolute;
        top: 140px;
        left: 0;
        z-index: 1000;
        width: 100%;
        text-align: left !important;
        padding-right: 20px;
        padding-left: 20px;
    }

    header.header .header-main nav ul li {
        border-bottom:1px solid #dac0a7;
        display: block !important;
    }

    header.header .header-main nav ul li ul li {
        padding: 10px 4px;
    }

    .top-navigation li.has-children a.open-arrow {
        display: inline-block;
        background: url(/images/ico-select.svg) center center no-repeat;
        width: 32px;
        height: 32px;
        position: absolute;
        top: 5px;
        right: 0;
    }
    .top-navigation li.has-children a.open-arrow.is-active {
        transform: rotate(180deg);
    }

    .top-navigation li.has-children ul {
        z-index: 100;
        margin-left: 0;
        display: none;
        position: relative;
        width: 100%;
        top: 0;
    }
}

@media screen and (max-width: 767px) and (min-width: 0) {

    .burger {
        margin-top: 0;
        float: none;
        position: absolute;
        right: 5px;
        margin-top: -6px;
    }

    header.header .header-main nav {
        top: 100px;
        padding-right: 10px;
        padding-left: 10px;
    }

    header.header .header-main .btn {
        right: 48px;
    }
}

@media screen and (max-width: 354px) and (min-width: 0) {
    header.header .header-main .btn {
        font-size: 9px;
    }
}

