.about-section-right ul li {
    font-size: 1.7rem;
}

.about-section-right ul {
    list-style-position: outside;
    margin-left: 40px
}

.products-section .product-card .product-card-info .product-card-info-slider {
    margin-bottom: -40px!important;
}

.btn-hugo:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
    margin-left: 10px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../images/hat-brown.svg) !important;
    height: 28px;
    width: 28px;
}

.btn-buy {
    cursor: pointer;
    position: relative !important;
}

.btn-buy:after {
    position: absolute !important;
    margin: 0 !important;
    right: 8px !important;
    top: 10px !important;
}

.products-recommended-items .btn-buy {
   /* position: absolute !important;
    width: 120px;
    bottom: 0px;
    right: 0px;*/
}

.iti { width: 100%}

.products-section .variants-carousel.disable>.owl-nav {
    display: none !important;
}

.custom-select {
    background-image: url(../images/ico-select-right.svg);
    background-size: 20px auto;
}

@media screen and (max-width: 1023px) and (min-width: 0) {


    .modal.modal-hugo .modal-content .modal-content-inner {
        padding: 40px 52px 20px 52px;
    }

    .photo-wrap {
      /*  padding: 60px 60px 72px 60px;*/
    }

    .photo-wrap .photo-inner {
       height: 414px;
    }

}

@media screen and (max-width: 755px) and (min-width: 0) {

    .photo-wrap .photo-inner {
        height: 360px;
    }
}

@media screen and (max-width: 666px) and (min-width: 0) {
    .modal.modal-hugo .modal-content {
       max-width: 100% !important;
    }

    .modal.modal-hugo .modal-content .modal-content-inner {
      padding: 20px 22px 20px 22px;
    }

    .photo-wrap {
       /* padding: 20px 20px 72px 20px;*/
    }

    .modal.modal-hugo.visible .modal-close {
        z-index: 2000;
        top: 0;
        left: auto;
        right: 0;
    }

}

@media screen and (max-width: 450px) and (min-width: 0) {
    .photo-wrap .photo-inner {
        height: 283px;
    }

    .photo-wrap .photo-inner .photo-inner-main .photo-selector .photo-selector-face {
        width: 96px;
    }
}

@media screen and (max-width: 360px) and (min-width: 0) {
    .photo-wrap .photo-inner {
        height: 252px;
    }
}

.product-detail-availability .pda-item {
    position: relative;
}

.product-detail-availability .pda-item:hover .reservation {
    display: block;
}

.reservation {
    display: none;
    z-index: 100;
    position: absolute;
    left: -135px;
    top: 0px;
    padding-top: 40px;
    width: 350px;
    background: transparent;
}

.reservation div {
    position: relative;
    padding: 40px;
    background: white;
    -webkit-box-shadow: 2px 6px 21px 0px rgba(0,0,0,0.33);
    box-shadow: 2px 6px 21px 0px rgba(0,0,0,0.33);
}

.reservation div:before {
    position: absolute;
    top: -10px;
    left: calc(50% - 10px);
    content: '';
    display: block;
    background: white;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
}

.reservation .btn-buy {
    width: 100%;
    margin-top: 20px;
}

.reservation p {
    line-height: 1.1em !important;
    padding: 0 !important;
}

.products-hugo {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 0px;
}

.products-hugo .products-wrap {
    grid-template-columns: 50% 50%;
}

@media screen and (max-width: 766px) and (min-width: 0) {
    .products-hugo .products-wrap {
        grid-template-columns: 100%;
    }
}

@media screen and (max-width: 766px) and (min-width: 0) {
    .cart-wrap-nav .cart-wrap-nav-item {
       float: none;
       text-align: center;
        padding-bottom: 0px;
        margin-bottom: 5px;
    }
    .cart-wrap-nav .cart-wrap-nav-item.active {
        border-bottom: 1px solid black;
    }
}

.buy-form {
    width: 60%;
    margin: 0 auto;
    text-align: left;
}

.buy-form label {
    text-align: left;
}

@media screen and (max-width: 650px) and (min-width: 0) {
    .buy-form {
        width: 80%;
    }
}

.price-label {
    font-size: 1.3rem;
    display: block;
    text-align: right;
    margin-top: 5px;
}


@media screen and (max-width: 1400px) and (min-width: 0) {
    .product-detail .product-detail-form-price .price {
       display: inline-block;
        margin-top: 50px;
    }

    .price-label {
        text-align: left;
    }
}

.modal-delivery-points .modal-content {
    width: 90%;
    margin: 0 auto;
}

.account-table tr td:first-child,
.account-table tr th:first-child {
    padding-left: 20px;
}

.account-table .my-order-detail:hover,
.account-table .my-order-detail tr:hover,
.account-table .my-order-detail tr td:hover{
    background: transparent !important;
}


.account-table .my-order-delivery-payment tr,
.account-table .my-order-delivery-payment td,
.account-table .my-order-delivery-payment th,
.account-table .my-order-delivery-payment + tr,
.account-table .my-order-delivery-payment + tr td,
.account-table .my-order-delivery-payment + tr th,
.account-table .my-order-delivery-payment + tr + tr,
.account-table .my-order-delivery-payment + tr + tr td,
.account-table .my-order-delivery-payment + tr + tr th{
    padding-top: 0;
    padding-bottom: 0;
}

.account-table .my-order-delivery-payment td,
.account-table .my-order-addresses td {
    font-size: 1.5em;
}

.account-table .my-order-delivery-payment td {
    padding-top: 30px;
    padding-bottom: 10px;
}

.account-table .my-order-addresses tr,
.account-table .my-order-addresses td,
.account-table .my-order-addresses th,
.account-table .my-order-addresses + tr,
.account-table .my-order-addresses + tr td,
.account-table .my-order-addresses + tr th {
    padding-top: 0;
    padding-bottom: 0;
}

.account-table .my-order-addresses td {
    padding-top: 30px;
    padding-bottom: 10px;
}

.account-table .my-order-detail tr:last-child td {
    padding-bottom: 20px;
    border-bottom: #dac0a7 solid 1px;
}

.account-table tr td {
    cursor: pointer;
}

.account-table .my-order-detail tr td,
.account-table .my-order-detail tr th {
    cursor: default;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-right: 10px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.heureka {
    position: relative;
    top: 20px;
}

@media screen and (max-width: 890px) and (min-width: 0) {
    .heureka {
        text-align: center;
        top: 10px;
        margin-bottom: 20px;
    }
}


@media screen and (max-width: 350px) and (min-width: 0) {

    .heureka img {
        width: 100%;
        height: auto;
    }
}

.coupon-remove {
    position: relative;
}
.coupon-remove:before {
    display: inline-block;
    content: '';
    width: 40px;
    height: 40px;
    background-image:url(../images/ico-trash.svg);
    background-size: 40px 40px;
    position: absolute;
    top: -7px;
    left: -2px;
}


iframe {
    width: 100%;
}

@media screen and (max-width: 480px) and (min-width: 0) {
 .about-content-side {
     width: 100% !important;
 }
    .our-rules-items li {
        width: 100%!important;
    }
}
