/* !Burger (Author: mail@jandousek.cz) */

.burger {
    width: 44px;
    height: 44px;
    position: relative;
    display: inline-block;
    font-size: 0;
}

/*
  lines
  ***
 */

.burger-lines, .burger-lines:before, .burger-lines:after {
    width: 26px;
    height: 2px;
    position: absolute;
    background-color: #000;
    -webkit-transition: 150ms;
    -o-transition: 150ms;
    transition: 150ms;
}
.burger-lines {
    left: 9px;
    top: 50%;
    margin-top: -1px;
}
.burger-lines:before, .burger-lines:after {
    content: '';
}
.burger-lines:before {
    top: -8px;
}
.burger-lines:after {
    bottom: -8px;
}

/* actions */

.burger.is-active .burger-lines {
    background-color: transparent !important;
}
.burger.is-active .burger-lines:before, .burger.is-active .burger-lines:after {
    background-color: #e14a4a;
}
.burger.is-active .burger-lines:before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.burger.is-active .burger-lines:after {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}