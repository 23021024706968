.rc-slider-rail {
   height: 1px !important;
   background: #dac0a7 !important
}
.rc-slider-step {
   height: 1px !important;
}

.rc-slider-track {
   height: 1px !important;
   background:  #dac0a7 !important;
}

.rc-slider-handle {
   margin-top: -16px !important;
   width: 32px !important;
   height: 32px !important;
   background: #fff !important;
   border: none !important;
   border-radius: 0 !important;
}

.rc-slider-small .rc-slider-handle {
   margin-top: -9px !important;
   width: 18px !important;
   height: 18px !important;
   background: #fff !important;
   border: none !important;
   border-radius: 0 !important;
}

.rc-slider-handle:after {
   content: "";
   display: block;
   position: absolute;
   top: 4px;
   left: 4px;
   width: 24px;
   height: 24px;
   background: #dac0a7;
}

.rc-slider-small .rc-slider-handle:after {
   content: "";
   display: block;
   position: absolute;
   top: 2px;
   left: 2px;
   width: 14px;
   height: 14px;
   background: #dac0a7;
}

.rc-slider-handle-dragging {
   box-shadow: 0 0 0 #000 !important;
}

.rc-slider-handle:active {
   box-shadow: 0 0 5px #dac0a7 !important;
}

.rc-slider.rc-slider-vertical {
   padding: 0 19px;
   height: 93% !important;
}

.rc-slider.rc-slider-vertical .rc-slider-rail {
   width: 1px !important;
   height: 100% !important;
   background: #dac0a7 !important
}

.rc-slider-vertical .rc-slider-handle {
   margin-left: -9px !important;
}

.rc-slider.rc-slider-vertical .rc-slider-track {
   display: none;
}

.photo-selector-big .size-range-dark-wrap {
   top: 50px;
   right: 8px;
   padding-top: 13px;
}

.rc-slider-vertical .rc-slider-mark {
   display: none;
}